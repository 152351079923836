<template>
  <v-app>
    <router-view v-if="filtersLoad" />
    <div v-else class="main-preloader widget-preloader chart-preloader">
      <Loader
          ref="preloader"
          :animationData="require('@/assets/images/Loader.json')"
          :autoPlay="true"
          :loop="true"
          :speed="1"
      />
    </div>
    <UpdatePopup />
  </v-app>
</template>

<script>
import UpdatePopup from '@/components/ui/popups/Update';
import { ws } from '@/utils/socket';
import debounce from '@/mixins/debounce';
import 'vuetify/dist/vuetify.min.css';
import { mapActions, mapMutations } from 'vuex';
import { SciChartSurface } from 'scichart/Charting/Visuals/SciChartSurface';
import ToasterSuccess from "@/components/toasters/ToasterSuccess.vue";
import moment from "moment/moment";

export default {
  name: 'App',

  components: {
    UpdatePopup
  },

  mixins: [debounce],

  data: () => ({
    filtersLoad: false,
  }),

  methods: {
    ...mapActions({
      getFilters: 'filters/getFilters',
      getExchangeSlugs: 'filters/getExchangeSlugs',
      getCoinSlugs: 'filters/getCoinSlugs',
      getExchangesInfo: 'filters/getExchangesInfo',
      getCoinsInfo: 'filters/getCoinsInfo',
    }),

    ...mapMutations({
      SET_LAYOUT: 'layouts/SET_LAYOUT',
    }),

    resizeEventHandler() {
      this.debounce(this.SET_LAYOUT);
    }
  },

  async created() {
    await ws.initWebSocket();
    ws.subscribe(
        'ANNOUNCEMENTS',
        {
          method: 'SUBSCRIBE',
          params: {}
        },
        (response) => this.debounce(() => {
          const res = JSON.parse(response.response);
          let modifyTitle = null;
          let type = null;
          let time = null;
          let description = null;
          let pair = null;

          if (res.category === 'cryptocurrency_planned_listing' || res.category === 'cryptocurrency_planned_delisting') {
            const title = res.exchangeName;
            modifyTitle = title.toLowerCase()[0].toUpperCase() + title.toLowerCase().slice(1);
            type = res.category === 'cryptocurrency_planned_listing' ? 'listing' : 'delisting';
            time = res.publishedAt;
            description = res.description;
            pair = res.tradePairs.join(', ');
          } else {
            const title = res.exchange;
            time = new Date();
            description = res.message;
            modifyTitle = title.toLowerCase()[0].toUpperCase() + title.toLowerCase().slice(1);
            type = res.category === 'cryptocurrency_fact_listing' ? 'listing' : 'delisting';
            pair = res.currency;
          }

          this.$toast.success({
            component: ToasterSuccess,
            props: {
              type: type,
              title: `${modifyTitle} ${pair}`,
              description: description,
              time: moment(time).format('YYYY/MM/DD HH:mm')
            },
          });
        }, 100)
    );
  },

  mounted() {
    this.SET_LAYOUT();
    window.addEventListener('resize', this.resizeEventHandler);

    Promise.all([
      this.getFilters(),
      this.getCoinSlugs(),
      this.getCoinsInfo(),
      this.getExchangesInfo(),
      this.getExchangeSlugs()
    ]).then(() => {
      this.filtersLoad = true;
      this.$bus.$emit('inventory-loaded');
    }).catch(() => {
      this.filtersLoad = true;
      // this.$router.push({ path: '/maintenance' }).catch(() => {});
    });

    SciChartSurface.setRuntimeLicenseKey('8ReoBdcO9CKUGoDP+1aghBgoOxO74Zbq22mSBWaJS0YOkxZMXvucXK3JrKP6NTZ8/Vw37o/qbHpuBCT495zRYkLo14Lenm6RjckmJX76+BDnL3QpcfxmK+rztromtjrCbu3j8DclYJOubqg/v/pX/vhhnFJH7C0Hg943ECOQWr2JapolfAn2Jz1u/NMxOprSHd9Suy5HZ4yUJcuzzq/6IyRDwOH6o4DVllrTfMiEZqu+wunfffaeDxst32vwmJxudS/ua2RNHC4tLVB2XO5iBcBuRUNMBys/UkbVhtjtH8pudWj7HeJ3mTy8U2V7oc4xqsWyDHc3kWZxudiEUvyJJhe05Ip/uh21kAo8TeBk7fh2Hkhzjaqiy9l6NP+kTxIiblI9rfxou6Tltrqu4FBfwWaRTUkmCQpmFIkmzzqwaMaxvHXjhHEw3riDY0waWuRCtXP4Q1Hq1kAw3spVomP7l7UTPd6nYm7hWORspOr8olWcDViEUhIqJB+fYW5vmaAbLyFDsz8M9yB6jluwrUIqfEJMATs6XDw3Er2EALXlwp+xZNuMUm+xJ6A9+LwL3WdcEreabYQDZDtRV2q/6jsJnhqoj0jco6wJNB3k0ZitbNfZ3+aMouvMf9NsbafoRUB7iwMqfMg=');
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeEventHandler);
    ws.unsubscribe(
        'markPrice',
        {
          method: 'UNSUBSCRIBE',
          params: ['markPrice']
        },
    );
    ws.close();
  },
};
</script>

<style>

</style>
